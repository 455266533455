import store from "@/app/core/store";
import PublicService from "@/app/core/api/public";
import ComunesService from "@/app/core/api/comunes";
import AuditoriaService from "@/app/core/api/auditoria";
import http from "@/app/core/config/axios.config";
import AlertsService from "@/app/shared/services/alerts.service";
import SeguridadService from "@/app/shared/services/seguridad.service";
import EnvironmentService from "@/app/shared/services/environment.service";
import {
  urlsNoEncoding,
  urlsNoAuditoria,
  urlsNoValidToken,
  urlsNoMostrarAlerta,
  urlsNoMostrarLoading,
} from "@/app/core/config/urls.config";

http.interceptors.request.use(
  async (request) => {
    if (!urlsNoMostrarLoading(request.url)) store.commit("switchLoading", true);

    const newRequest = request;
    const { token, refreshToken, publicToken } = store.getters;
    const isMethodToEncrypt = newRequest.method.toUpperCase() === "POST";

    if (token && !request.url.startsWith("/public")) newRequest.headers.Authorization = `Bearer ${token}`;
    else newRequest.headers["SV-Public-Key"] = publicToken;

    if (token && !urlsNoValidToken(newRequest.url) && !urlsNoAuditoria(newRequest.url)) {
      try {
        await PublicService.validarToken({ accessToken: token, refreshToken });
      } catch (error) {
        return store.dispatch("logout");
      }
    }

    if (EnvironmentService.isProd || EnvironmentService.isQa) {
      if (isMethodToEncrypt && !urlsNoEncoding(newRequest.url)) {
        if (!newRequest.headers["SV-Encrypt"]) {
          newRequest.headers["SV-Encrypt"] = true;
          newRequest.headers["SV-Encrypt-IV"] = EnvironmentService.SV_IV;
          newRequest.data = SeguridadService.encriptarDatos(newRequest.data);
        }
      }
    }

    return newRequest;
  },
  (error) => Promise.reject(error)
);

http.interceptors.response.use(
  (response) => {
    store.commit("switchLoading", false);
    const newResponse = response;
    const { config, data, status } = newResponse;
    const isMethodToEncrypt = config.method.toUpperCase() === "POST";

    if (EnvironmentService.isProd || EnvironmentService.isQa) {
      if (isMethodToEncrypt && !urlsNoEncoding(config.url)) {
        const iv = newResponse.headers["sv-encrypt-iv"];
        const dataDecrypted = SeguridadService.desencriptarDatos(data, iv);
        config.data = dataDecrypted;
        newResponse.data = JSON.parse(dataDecrypted);
      }
    }

    if (!urlsNoAuditoria(config.url)) AuditoriaService.saveAuditoria({ config: config, status: status, data }, true);

    return newResponse;
  },
  (error) => {
    store.commit("switchLoading", false);

    const { config, response } = error;

    if (response?.status === 401) {
      if (config.url.startsWith("/public") || config.url.startsWith("/ValidacionIdentidad")) {
        return PublicService.publicToken()
          .then((response) => {
            store.commit("setPublicToken", response.data.token);
            config.headers["SV-Public-Key"] = store.getters.publicToken;
            return http(config);
          })
          .catch(() => {
            store.dispatch("logout");
          });
      } else {
        const body = { accessToken: store.getters.token, refreshToken: store.getters.refreshToken };

        return ComunesService.refreshToken(body)
          .then((response) => {
            const { token, refreshToken } = response.data;
            store.commit("refreshToken", { token, refreshToken });
            config.headers.Authorization = `Bearer ${store.getters.token}`;
            return http(config);
          })
          .catch(() => {
            store.dispatch("logout");
          });
      }
    }

    if (!urlsNoAuditoria(config.url) && response)
      AuditoriaService.saveAuditoria({ config: config, status: response?.status, data: response?.data }, false);

    if (urlsNoMostrarAlerta(config.url)) {
      if (!error.response) AlertsService.error("Error", "Error de red");
      else {
        if (error.response && error.response.data.Errors?.length > 0) {
          error.response.data.Errors.map((error) => AlertsService.error("Error", error));
        } else {
          AlertsService.error("Error", error.response.data.Message);
        }
      }
    }

    return Promise.reject(error);
  }
);
