<template>
  <div id="app" class="vh100">
    <b-overlay spinner-variant="danger" :fixed="true" :z-index="1080" :show="isLoading" class="vh100">
      <router-view />
      <template v-if="!kookiesAceptadas">
        <footer class="c-footer-sv text-center fs-15 py-3 px-5">
          <b-button variant="link" class="px-2 py-0 btn-close-kookies" @click="validarUsoKookies()">
            <i class="las la-times" />
          </b-button>
          {{ mensajeBannerKookies }} Conoce más dando
          <a class="text-info" target="_blank" href="static/Politica_Uso_Cookies.pdf" @click="validarUsoKookies()">
            clic aquí.
          </a>
        </footer>
      </template>
    </b-overlay>
    <ModalConfirmaciones />
    <ModalSesionExpirada :modal="modal" @useModal="useModalSesionExpirada" />
  </div>
</template>

<script>
const signalR = require("@microsoft/signalr");
import PublicService from "@/app/core/api/public";
import AuditoriaService from "@/app/core/api/auditoria";
import EnvironmentService from "./shared/services/environment.service";

// COMPONENTS
import ModalConfirmaciones from "@/app/core/components/ModalConfirmaciones.vue";
import ModalSesionExpirada from "@/app/core/components/ModalSesionExpirada.vue";

export default {
  components: { ModalSesionExpirada, ModalConfirmaciones },
  data() {
    return { modal: { isOpen: false }, isGetParametros: false, kookiesAceptadas: false };
  },
  computed: {
    mensajeBannerKookies() {
      return this.$store.getters.mensajeInicioBanner;
    },
    isLoading() {
      return this.$store.getters.loading;
    },
    version() {
      return this.$store.getters.version;
    },
  },
  created() {
    PublicService.getIp().then((response) => {
      this.$store.commit("setIp", response.ip);
    });

    if (location.pathname !== "/login") {
      PublicService.publicToken().then((response) => {
        this.$store.commit("setPublicToken", response.data.token);
        AuditoriaService.getAccionesAuditoria();
        PublicService.getParametrosIniciales().then((response) => {
          this.$store.dispatch("updateParams", response.data);
        });
      });
    }
  },
  mounted() {
    if (this.$store.getters.isLogin) {
      this.conectarHubLogout();
    }
    document.addEventListener("keydown", function (event) {
      if (event.code === "F12") {
        event.preventDefault();
      }
    });
  },
  onIdle() {
    if (this.$store.getters.isLogin) this.useModalSesionExpirada();
  },
  methods: {
    useModalSesionExpirada() {
      this.modal.isOpen = !this.modal.isOpen;
    },
    conectarHubLogout() {
      const { token, idAcceso, requestGrupo } = this.$store.getters;

      let connection = new signalR.HubConnectionBuilder()
        .withUrl(`${EnvironmentService.baseUrl}/chatHub`, { accessTokenFactory: () => token })
        .build();

      connection.on("ReceiveMessage", (_user, tipo) => {
        if (tipo === "LOGOUT") {
          this.$router.push("/logout");
        }
      });

      connection.start().then(() => connection.invoke("AddToGroup", idAcceso.toString(), requestGrupo.grupo));
    },
    validarUsoKookies() {
      this.kookiesAceptadas = true;
    },
  },
};
</script>
