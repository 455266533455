var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: { centered: "", "hide-footer": "", "hide-header": "" },
      on: {
        close: function($event) {
          return _vm.closeModal()
        },
        hidden: function($event) {
          return _vm.closeModal()
        }
      },
      model: {
        value: _vm.modal.isOpen,
        callback: function($$v) {
          _vm.$set(_vm.modal, "isOpen", $$v)
        },
        expression: "modal.isOpen"
      }
    },
    [
      _c(
        "b-row",
        { staticClass: "p-4" },
        [
          _c("b-col", { attrs: { cols: "12" } }, [
            _c("h5", { staticClass: "primary text-center" }, [
              _vm._v(_vm._s(_vm.modal.titulo))
            ]),
            _c("div", { staticClass: "p-3 content-center" }, [
              _c("i", { class: "las fs-icon-confirms " + _vm.tipoConfirm })
            ]),
            _c("p", { staticClass: "primary text-center py-2" }, [
              _vm._v(_vm._s(_vm.modal.mensaje))
            ])
          ]),
          _c(
            "b-col",
            { staticClass: "content-center", attrs: { cols: "12" } },
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "danger" },
                  on: {
                    click: function($event) {
                      return _vm.closeModal()
                    }
                  }
                },
                [_vm._v("Aceptar")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }