var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vh100", attrs: { id: "app" } },
    [
      _c(
        "b-overlay",
        {
          staticClass: "vh100",
          attrs: {
            "spinner-variant": "danger",
            fixed: true,
            "z-index": 1080,
            show: _vm.isLoading
          }
        },
        [
          _c("router-view"),
          !_vm.kookiesAceptadas
            ? [
                _c(
                  "footer",
                  { staticClass: "c-footer-sv text-center fs-15 py-3 px-5" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "px-2 py-0 btn-close-kookies",
                        attrs: { variant: "link" },
                        on: {
                          click: function($event) {
                            return _vm.validarUsoKookies()
                          }
                        }
                      },
                      [_c("i", { staticClass: "las la-times" })]
                    ),
                    _vm._v(
                      " " +
                        _vm._s(_vm.mensajeBannerKookies) +
                        " Conoce más dando "
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "text-info",
                        attrs: {
                          target: "_blank",
                          href: "static/Politica_Uso_Cookies.pdf"
                        },
                        on: {
                          click: function($event) {
                            return _vm.validarUsoKookies()
                          }
                        }
                      },
                      [_vm._v(" clic aquí. ")]
                    )
                  ],
                  1
                )
              ]
            : _vm._e()
        ],
        2
      ),
      _c("ModalConfirmaciones"),
      _c("ModalSesionExpirada", {
        attrs: { modal: _vm.modal },
        on: { useModal: _vm.useModalSesionExpirada }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }